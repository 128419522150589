import { Avatar } from 'molecules/avatars';
import { Skeleton } from 'molecules/skeletons';

export const TeamAvatar = ({ type = 'card', team, loading = false, rootLink = '', tailwind }) => {
	const props = {
		icon: '',
		thumbnail: team.thumbnail,
		title: team.name,
		subtitle: `@${team.uuid}`,
		href: `${rootLink}/teams/${team.uuid}`,
		badge: team.isPro ? team.membership : '',
		tailwind,
	};

	// Render
	if (loading) return <Skeleton className='w-20' />;
	return <Avatar type={type} {...props} />;
};
