import { Li, Span } from '@playbooks/interface/html';
import { SectionSubtitle, SectionText } from '@playbooks/interface/sections';
import { Skeleton } from 'molecules/skeletons';
import { toCurrency } from 'utils';

export const CurrencyItem = ({ keyName, disclaimer, loading, value, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-between' space='space-x-8' spacing='py-4' {...tailwind?.li}>
			<Span display='flex-start' space='space-x-4'>
				<SectionSubtitle fontSize='text-sm'>{keyName}</SectionSubtitle>
				<SectionSubtitle fontSize='text-sm'>{disclaimer}</SectionSubtitle>
			</Span>
			{loading ? <Skeleton className='w-20' /> : <SectionText fontSize='text-sm'>{toCurrency(value)}</SectionText>}
		</Li>
	);
};
