import { Card, CardBody, CardHeader, CardTitle } from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const SelectSkeleton = ({ tailwind }) => (
	<Card border='border-b' borderRadius='' display='flex-between' space='space-x-4' spacing='p-4' {...tailwind?.card}>
		<Span display='flex-start' space='space-x-4' width='w-full'>
			<CardHeader aspect='aspect-[1/1]' borderRadius='rounded-md' bgColor='' width='w-10' {...tailwind?.header}>
				<SkeletonWrapper />
			</CardHeader>
			<CardBody spacing='' width='w-2/3'>
				<CardTitle display=''>
					<SkeletonWrapper />
				</CardTitle>
			</CardBody>
		</Span>
		<Span aspect='aspect-[1/1]' width='w-8' {...tailwind?.action}>
			<SkeletonWrapper />
		</Span>
	</Card>
);

export { SelectSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
