import { Card, CardBody } from '@playbooks/interface/cards';
import { P } from '@playbooks/interface/fonts';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const SectionSkeleton = ({ count, grid, tailwind }) => (
	<Card border='' spacing='py-4'>
		<CardBody space='space-y-4' spacing='py-4'>
			<P>
				<SkeletonWrapper width='75%' />
			</P>
			<P>
				<SkeletonWrapper width='100%' />
			</P>
			<P>
				<SkeletonWrapper width='100%' />
			</P>
			<P>
				<SkeletonWrapper width='100%' />
			</P>
		</CardBody>
	</Card>
);

export { SectionSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
