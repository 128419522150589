import { Li, Span, Ul } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const LogSkeleton = ({ tailwind }) => (
	<Ul spacing='px-4 py-2' width='w-full'>
		<Li display='flex-start' space='space-x-4' spacing='py-2' width='w-full'>
			<Span flex='shrink-0' width='w-10'>
				<SkeletonWrapper />
			</Span>
			<Span width='w-1/2'>
				<SkeletonWrapper />
			</Span>
		</Li>
	</Ul>
);

export { LogSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
