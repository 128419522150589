import dynamic from 'next/dynamic';
const StarRatings = dynamic(() => import('react-star-ratings'), { ssr: false });

import { useTheme } from 'contexts';

const StarRatingWrapper = ({ value, dimensions, spacing, onChange }) => {
	const theme = useTheme();

	// Computed
	const computed = {
		starRatedColor: theme.isDark ? '#f97316' : '#06b6d4',
		starHoverColor: theme.isDark ? '#f97316' : '#06b6d4',
		starEmptyColor: theme.isDark ? '#475569' : '#cbd5e1',
	};

	// Render
	return (
		<StarRatings
			rating={value}
			numberOfStars={5}
			starRatedColor={computed.starRatedColor}
			starHoverColor={computed.starHoverColor}
			starEmptyColor={computed.starEmptyColor}
			starDimension={dimensions}
			starSpacing={spacing}
			changeRating={onChange}
		/>
	);
};

export { StarRatingWrapper };

// Docs
// https://www.npmjs.com/package/react-star-ratings
