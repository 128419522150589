import { Card, CardBody, CardFooter, CardTitle } from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { OwnerAvatar } from 'components/owner/owner-avatar';
import { StarRatingWrapper } from 'components/wrappers/star-rating-wrapper';
import { Skeleton } from 'molecules/skeletons';
import { formatDate } from 'utils';

const RatingCard = ({ owner, ownerType, rating, text, date, loading, children, tailwind }) => {
	// Render
	if (loading) return <Skeleton type='rating' tailwind={tailwind} />;
	return (
		<Card border='border-b' borderRadius='' spacing='' {...tailwind?.card}>
			<CardBody display='flex-between-top' spacing='py-4'>
				<Span space='space-y-4' width='w-full'>
					{rating && <StarRatingWrapper value={rating} dimensions='20px' spacing='5px' />}
					<CardTitle size='p' fontWeight='font-light' tracking='tracking-wide' className='line-clamp-3'>
						{text}
					</CardTitle>
				</Span>
				{children}
			</CardBody>
			<CardFooter border='' spacing='py-4'>
				<OwnerAvatar type='card' owner={owner} variant={ownerType} />
				<Span flex='shrink-0'>{`Posted ${formatDate(date, 'MMMM, YYYY')}`}</Span>
			</CardFooter>
		</Card>
	);
};

export { RatingCard };
