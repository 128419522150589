import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardPretitle,
	CardSubtitle,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const GridSkeleton = ({ tailwind }) => {
	// Render
	return (
		<Card border='border' display='flex-column' spacing='' {...tailwind?.card}>
			<CardHeader aspect='aspect-[16/9]' bgColor=''>
				<SkeletonWrapper className='w-full' />
			</CardHeader>
			<CardBody space='space-y-2' spacing='p-4'>
				<CardPretitle display=''>
					<SkeletonWrapper width='25%' />
				</CardPretitle>
				<CardTitle display=''>
					<SkeletonWrapper width='50%' />
				</CardTitle>
				<CardSubtitle>
					<SkeletonWrapper width='75%' />
				</CardSubtitle>
			</CardBody>
			<CardFooter border='' display='flex-between' space='space-x-4' spacing='p-4'>
				<Span display='flex-start' space='space-x-4'>
					<Span width='w-24'>
						<SkeletonWrapper />
					</Span>
					<Span width='w-24'>
						<SkeletonWrapper />
					</Span>
				</Span>
			</CardFooter>
		</Card>
	);
};

export { GridSkeleton };
