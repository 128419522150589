import {
	Card,
	CardActions,
	CardBody,
	CardHeader,
	CardIcon,
	CardSubtitle,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { borderProps } from '@playbooks/interface/utils';
import { Skeleton } from 'molecules/skeletons';
import { HtmlType } from 'types';
import { capitalize } from 'utils';

type iActivityCard = HtmlType & {
	type?: string;
	icon?: string;
	title?: string;
	subtitle?: string;
	text?: string;
	loading?: string;
};

export const ActivityCard = ({ type, icon, title, subtitle, text, loading, tailwind }: iActivityCard) => {
	// Render
	if (loading) return <Skeleton type='activity' tailwind={tailwind} />;
	return (
		<Card border='border-b' borderRadius='' display='flex-between' space='space-x-4' spacing='py-4' {...tailwind?.card}>
			<CardBody display='flex-start-top' flex='grow' space='space-x-4' spacing=''>
				<CardHeader aspect='aspect-[1/1]' borderRadius='rounded-md' display='flex-middle' spacing='' width='w-8'>
					<CardIcon type='fad' icon={icon} fontSize='text-base' />
				</CardHeader>
				<Span className='space-y-1'>
					{title && <CardTitle>{title}</CardTitle>}
					{subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
					{text && <CardText>{text}</CardText>}
				</Span>
			</CardBody>
			<CardActions display='flex-end' flex='shrink-0'>
				<Span
					border='border'
					borderRadius='rounded-md'
					color='gray-500 dark:gray-400'
					display='flex-middle'
					fontSize='text-xs'
					fontWeight='font-light'
					spacing='px-2 py-1'
					{...borderProps}>
					{capitalize(type)}
				</Span>
			</CardActions>
		</Card>
	);
};
