import { Avatar, AvatarBody, AvatarImg, AvatarText } from '@playbooks/interface/avatars';
import { LinkWrapper } from '@playbooks/interface/links';

export const MetaAvatar = ({ thumbnail, title, href }) => (
	<LinkWrapper group='group' href={href}>
		<Avatar display='flex-start'>
			<AvatarImg size='xs' src={thumbnail} alt={`${title} photo`} />
			<AvatarBody>
				<AvatarText fontSize='text-sm' hover='group-hover:underline'>
					{title}
				</AvatarText>
			</AvatarBody>
		</Avatar>
	</LinkWrapper>
);
