import { Card, CardBody } from '@playbooks/interface/cards';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const StatSkeleton = ({ tailwind }) => (
	<Card>
		<CardBody space='space-y-2' className=''>
			<h4>
				<SkeletonWrapper width='50%' />
			</h4>
			<h6>
				<SkeletonWrapper width='75%' />
			</h6>
		</CardBody>
	</Card>
);

export { StatSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
