import { Badge } from '@playbooks/interface/badges';
import { Small } from '@playbooks/interface/fonts';
import { Li } from '@playbooks/interface/html';
import { SectionSubtitle } from '@playbooks/interface/sections';
import { Skeleton } from 'molecules/skeletons';

export const BadgeItem = ({ keyName, loading, value, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-between' space='space-x-8' spacing='py-4' {...tailwind?.li}>
			<SectionSubtitle fontSize='text-sm'>{keyName}</SectionSubtitle>
			{loading ? <Skeleton className='w-20' /> : value ? <Badge type={value}>{value}</Badge> : <Small>--</Small>}
		</Li>
	);
};
