import { Div } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';
import { listBuilder } from 'utils';

const FilterSkeleton = ({ count, tailwind }) => {
	// Render
	return (
		<Div display='flex-start' space='space-x-8' width='w-full' {...tailwind?.div}>
			{listBuilder(count).map((v, i) => (
				<SkeletonWrapper key={i} className='!w-40' {...tailwind?.skeleton} />
			))}
		</Div>
	);
};

export { FilterSkeleton };
