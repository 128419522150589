import { Li } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { SectionSubtitle } from '@playbooks/interface/sections';
import { Skeleton } from 'molecules/skeletons';

export const ChecklistItem = ({ keyName, loading, value, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-start' space='space-x-4' spacing='py-4' {...tailwind?.li}>
			{loading ? <Skeleton className='w-20' /> : <FadIcon icon='circle-check' color='gray-500' {...tailwind?.icon} />}
			<SectionSubtitle fontSize='text-sm'>{value}</SectionSubtitle>
		</Li>
	);
};
