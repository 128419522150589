import { Badge } from '@playbooks/interface/badges';
import {
	Card,
	CardActions,
	CardBody,
	CardHeader,
	CardIcon,
	CardImg,
	CardSubtitle,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { Skeleton } from 'molecules/skeletons';
import { HtmlType } from 'types';

type iPreviewCard = HtmlType & {
	icon?: string;
	photo?: string;
	title?: string;
	subtitle?: string;
	text?: string;
	loading?: string;
	tailwind?: string;
};

const PreviewActionCard = ({
	icon,
	photo,
	status,
	title,
	subtitle,
	text,
	loading,
	tailwind,
	children,
}: iPreviewCard) => {
	// Render
	if (loading) return <Skeleton type='preview' tailwind={tailwind} />;
	return (
		<Card border='border-b' borderRadius='' display='flex-between' space='space-x-4' spacing='py-4' {...tailwind?.card}>
			<Span display='flex-start' flex='grow' space='space-x-4' {...tailwind?.span}>
				<CardHeader
					aspect='aspect-[1/1]'
					borderRadius='rounded-md'
					display='flex-middle'
					flex='shrink-0'
					overflow='overflow-hidden'
					spacing=''
					width='w-12'
					{...tailwind?.header}>
					{photo ? (
						<CardImg src={photo} width='w-full' {...tailwind?.photo} />
					) : (
						<CardIcon icon={icon} {...icon} {...tailwind?.icon} />
					)}
				</CardHeader>
				<CardBody space='' spacing='' width='w-full' {...tailwind?.body}>
					<CardTitle {...tailwind?.title}>
						{title && <Span>{title}</Span>}
						{status && <Badge size='xs'>{status}</Badge>}
					</CardTitle>
					{subtitle && <CardSubtitle fontSize='text-sm'>{subtitle}</CardSubtitle>}
					{text && <CardSubtitle fontSize='text-sm'>{text}</CardSubtitle>}
				</CardBody>
			</Span>
			<CardActions display='flex-end' flex='shrink-0'>
				{children}
			</CardActions>
		</Card>
	);
};

export { PreviewActionCard };
