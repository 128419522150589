import { Avatar, AvatarBody, AvatarImg, AvatarText } from '@playbooks/interface/avatars';
import { LinkWrapper } from '@playbooks/interface/links';

export const HeroAvatar = ({ thumbnail, title, subtitle, href }) => (
	<LinkWrapper group='group' href={href}>
		<Avatar display='flex-start'>
			<AvatarImg size='md' src={thumbnail} alt={`${title} photo`} />
			<AvatarBody>
				<AvatarText fontSize='text-base' fontWeight='font-bold' hover='group-hover:underline'>
					{title}
				</AvatarText>
				<AvatarText fontSize='text-sm' fontWeight='font-light'>
					{subtitle}
				</AvatarText>
			</AvatarBody>
		</Avatar>
	</LinkWrapper>
);
