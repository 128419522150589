import { Div } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const DropSkeleton = ({ count, tailwind }) => {
	// Render
	return (
		<Div spacing='px-4 py-2' width='w-full' {...tailwind?.div}>
			<SkeletonWrapper className='!h-6 !w-40' {...tailwind?.skeleton} />
		</Div>
	);
};

export { DropSkeleton };
