import { AvatarImg } from '@playbooks/interface/avatars';
import { Li } from '@playbooks/interface/html';
import { SectionSubtitle } from '@playbooks/interface/sections';
import { Skeleton } from 'molecules/skeletons';

export const AvatarItem = ({ keyName, loading, value, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-between' space='space-x-8' spacing='py-4' {...tailwind?.li}>
			<SectionSubtitle fontSize='text-sm'>{keyName}</SectionSubtitle>
			{loading ? <Skeleton className='w-20' /> : <AvatarImg size='xs' src={value} alt={keyName} />}
		</Li>
	);
};
