import { Li } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { SectionSubtitle } from '@playbooks/interface/sections';
import { Skeleton } from 'molecules/skeletons';

export const IconItem = ({ keyName, loading, value, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-between' space='space-x-8' spacing='py-4' {...tailwind?.li}>
			<SectionSubtitle fontSize='text-sm'>{keyName}</SectionSubtitle>
			{loading ? <Skeleton className='w-20' /> : <FadIcon icon={value} fontSize='text-xl' {...tailwind?.icon} />}
		</Li>
	);
};
