import { Card, CardBody } from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

export const ActivitySkeleton = ({ count, grid, tailwind }) => (
	<Card spacing='p-4' className='flex-start-top space-x-4'>
		<Span className='w-8'>
			<h4>
				<SkeletonWrapper width='100%' className='rounded-md' />
			</h4>
		</Span>
		<CardBody space='space-y-2' width='w-full'>
			<h6>
				<SkeletonWrapper width='30%' />
			</h6>
			<h6>
				<SkeletonWrapper width='60%' />
			</h6>
			<h6>
				<SkeletonWrapper width='80%' />
			</h6>
		</CardBody>
	</Card>
);
