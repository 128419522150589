import { Fragment } from 'react';

import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';
import { listBuilder } from 'utils';

const ThumbnailSkeleton = ({ count = 3, grid, tailwind }) => {
	// Render
	return (
		<Fragment>
			{listBuilder(count).map((s, i) => (
				<SkeletonWrapper key={i} className='aspect-[1/1] rounded-sm w-10' />
			))}
		</Fragment>
	);
};

export { ThumbnailSkeleton };
