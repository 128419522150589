import { useEffect } from 'react';

import { Modal, ModalHeader, ModalTitle } from '@playbooks/interface/modals';
import { AccountSwitchFragment } from 'components/account/account-switch-fragment';
import { useAccount, useSession } from 'contexts';

const AccountSwitchModal = ({ open, setOpen, onNext }) => {
	const account = useAccount();
	const session = useSession();

	// Hooks
	useEffect(() => {
		return () => setOpen(false);
	}, []);

	// Methods
	const onClose = () => setOpen(false);

	const onSelect = (type, newAccount) => {
		account.onSwitch(type, newAccount);
		onNext();
	};

	// Render
	return (
		<Modal open={open} onClose={onClose}>
			<ModalHeader onClose={onClose}>
				<ModalTitle>Switch Account</ModalTitle>
			</ModalHeader>
			<AccountSwitchFragment session={session} account={account} onSelect={onSelect} />
		</Modal>
	);
};

export { AccountSwitchModal };
