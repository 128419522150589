import { Badge } from '@playbooks/interface/badges';
import { PreviewCard } from 'molecules/cards';

const TeamAboutCard = ({ team }) => {
	// Render
	return (
		<PreviewCard
			photo={team.thumbnail}
			title={team.name}
			text={'@' + team.uuid}
			tailwind={{
				card: { bgColor: '', border: '', borderRadius: '', spacing: '' },
				body: { space: '' },
				title: { size: 'h6', fontSize: 'text-base' },
			}}>
			<Badge size='xs' textTransform='uppercase' tracking='tracking-wider'>
				{team.membership} Plan
			</Badge>
		</PreviewCard>
	);
};

export { TeamAboutCard };
