import { Li } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { SectionSubtitle } from '@playbooks/interface/sections';

export const BooleanItem = ({ keyName, loading, icon, value, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-between' space='space-x-8' spacing='py-4' {...tailwind?.li}>
			<SectionSubtitle fontSize='text-sm'>{keyName}</SectionSubtitle>
			{loading ? (
				<Skeleton className='w-20' />
			) : (
				<FadIcon
					icon={value ? 'circle-check' : 'circle-xmark'}
					color={value ? 'green-400' : 'red-500'}
					fontSize='text-xl'
				/>
			)}
		</Li>
	);
};
