import { Fragment } from 'react';

import { FormGroup, FormInput, FormLabel } from '@playbooks/interface/forms';
import { FilestackPhotoWrapper } from 'components/wrappers/filestack-photo-wrapper';

const RegisterFields = ({ user, setUser }) => {
	// Render
	return (
		<Fragment>
			<FormGroup>
				<FilestackPhotoWrapper
					title='Thumbnail'
					value={user.thumbnail || ''}
					onChange={file => setUser({ ...user, thumbnail: file.url })}
				/>
			</FormGroup>

			<FormGroup>
				<FormLabel>Full Name</FormLabel>
				<FormInput
					value={user.name || ''}
					placeholder='Full name'
					onChange={e => setUser({ ...user, name: e.target.value })}
					className='rounded-l-0'
				/>
			</FormGroup>

			<FormGroup>
				<FormLabel>Email Address</FormLabel>
				<FormInput
					value={user.email || ''}
					placeholder='Email address'
					onChange={e => setUser({ ...user, email: e.target.value })}
					className='rounded-l-0'
				/>
			</FormGroup>

			<FormGroup className='pb-4'>
				<FormLabel>Password</FormLabel>
				<FormInput
					type='password'
					value={user.password || ''}
					placeholder='••••••••'
					onChange={e => setUser({ ...user, password: e.target.value })}
					className='rounded-l-0'
				/>
			</FormGroup>
		</Fragment>
	);
};

export { RegisterFields };
