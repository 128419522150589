import { Badge } from '@playbooks/interface/badges';
import {
	Card,
	CardActions,
	CardBody,
	CardHeader,
	CardIcon,
	CardImg,
	CardPretitle,
	CardSubtitle,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { Skeleton } from 'molecules/skeletons';
import { HtmlType } from 'types';

type iTimelineCard = HtmlType & {
	icon?: string;
	photo?: string;
	pretitle?: string;
	title?: string;
	subtitle?: string;
	text?: string;
	meta?: any;
	loading?: string;
	tailwind?: string;
};

export const TimelineCard = ({
	icon,
	photo,
	status,
	pretitle,
	title,
	subtitle,
	text,
	meta,
	loading,
	tailwind,
	children,
}: iTimelineCard) => {
	// Render
	if (loading) return <Skeleton type='preview' tailwind={tailwind} />;
	return (
		<Card border='border-b' borderRadius='' display='flex-between' space='space-x-4' spacing='py-4' {...tailwind?.card}>
			<Span display='flex-start' flex='grow' space='space-x-4' {...tailwind?.span}>
				<CardHeader
					aspect='aspect-[1/1]'
					borderRadius='rounded-md'
					display='flex-middle'
					flex='shrink-0'
					overflow='overflow-hidden'
					spacing=''
					width='w-12'
					{...tailwind?.header}>
					{photo ? (
						<CardImg src={photo} alt={title + ' photo'} width='w-full' {...tailwind?.photo} />
					) : (
						<CardIcon icon={icon} {...icon} {...tailwind?.icon} />
					)}
				</CardHeader>
				<CardBody space='space-y-0' spacing='' width='w-full' {...tailwind?.body}>
					{pretitle && <CardPretitle>{pretitle}</CardPretitle>}
					<CardTitle {...tailwind?.title}>
						{title && <Span>{title}</Span>}
						{status && <Badge size='xs'>{status}</Badge>}
					</CardTitle>
					{subtitle && (
						<CardSubtitle className='line-clamp-1' {...tailwind?.subtitle}>
							{subtitle}
						</CardSubtitle>
					)}
					{text && (
						<CardSubtitle fontSize='text-sm' {...tailwind?.text}>
							{text}
						</CardSubtitle>
					)}
					{meta}
				</CardBody>
			</Span>
			<CardActions display='flex-end' flex='shrink-0'>
				{children}
			</CardActions>
		</Card>
	);
};
