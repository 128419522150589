import { BtnWrapper } from '@playbooks/interface/buttons';
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardIcon,
	CardImg,
	CardSubtitle,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { LinkWrapper } from '@playbooks/interface/links';

const PhotoCard = ({ icon, photo, title, subtitle, selected, tailwind, children }) => {
	// Render
	return (
		<Card
			display='flex-column'
			height='h-full'
			hover='group-hover:border-opacity-100 dark:group-hover:border-opacity-100 group-hover:shadow-md'
			spacing='p-4'
			{...tailwind?.card}>
			{selected && (
				<Span
					size='w-6 h-6'
					border='border'
					borderColor='border-gray-500'
					borderOpacity='border-opacity-50'
					borderRadius='rounded-full'
					bgColor='bg-green-500'
					display='flex-middle'
					position='absolute'
					inset='-top-1 -right-1'
					zIndex='z-10'>
					<FarIcon icon='check' color='white' />
				</Span>
			)}
			<CardHeader
				aspect='aspect-[1/1]'
				display='flex-middle'
				overflow='overflow-hidden'
				width='w-full'
				{...tailwind?.header}>
				{photo ? (
					<CardImg src={photo} animation='transition-all ease' alt={title + ' photo'} {...tailwind?.photo} />
				) : (
					<CardIcon icon={icon} animation='transition-all ease' {...tailwind?.icon} />
				)}
			</CardHeader>
			<CardBody spacing='pt-4' {...tailwind?.body}>
				{title && <CardTitle {...tailwind?.title}>{title}</CardTitle>}
				{subtitle && <CardSubtitle {...tailwind?.subtitle}>{subtitle}</CardSubtitle>}
			</CardBody>
			{children && (
				<CardFooter spacing='pt-4' {...tailwind?.footer}>
					{children}
				</CardFooter>
			)}
		</Card>
	);
};

export { PhotoCard };

export const PhotoBtnCard = props => (
	<BtnWrapper onClick={props.onClick} group='group' className='w-full'>
		<PhotoCard {...props} />
	</BtnWrapper>
);

export const PhotoLinkCard = props => (
	<LinkWrapper href={props.href} group='group' className='w-full'>
		<PhotoCard {...props} />
	</LinkWrapper>
);
