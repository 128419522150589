import { Card } from '@playbooks/interface/cards';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const FeaturedSkeleton = ({ count = 5, grid, tailwind }) => {
	// Render
	return (
		<Card bgColor='' border='' borderRadius='rounded-md' display='flex-column' spacing='' {...tailwind?.card}>
			<SkeletonWrapper width='100%' className='aspect-[1/1] rounded-md' />
		</Card>
	);
};

export { FeaturedSkeleton };
