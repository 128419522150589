import { Card, CardBody, CardHeader, CardTitle } from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const SearchSkeleton = ({ tailwind }) => (
	<Card border='border-b' borderRadius='' display='flex-between' space='space-x-4' spacing='p-4' {...tailwind?.card}>
		<Span display='flex-start' flex='grow' space='space-x-4' {...tailwind?.span}>
			<CardHeader aspect='aspect-[1/1]' borderRadius='rounded-md' bgColor='' width='w-10' {...tailwind?.header}>
				<SkeletonWrapper />
			</CardHeader>
			<CardBody space='space-y-2' spacing='' width='w-full'>
				<CardTitle display='' className='w-1/2'>
					<SkeletonWrapper />
				</CardTitle>
			</CardBody>
		</Span>
		<Span aspect='aspect-[1/1]' width='w-8'>
			<SkeletonWrapper />
		</Span>
	</Card>
);

export { SearchSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
