import { Fragment } from 'react';

import { FormGroup, FormInput, FormLabel } from '@playbooks/interface/forms';
import { TextLink } from '@playbooks/interface/links';

const LoginFields = ({ user, setUser }) => {
	// Render
	return (
		<Fragment>
			<FormGroup>
				<FormLabel>Email Address</FormLabel>
				<FormInput
					value={user.email || ''}
					placeholder='Email address'
					onChange={e => setUser({ ...user, email: e.target.value })}
					className='rounded-l-0'
				/>
			</FormGroup>

			<FormGroup className='pb-4'>
				<FormLabel>Password</FormLabel>
				<FormInput
					type='password'
					value={user.password || ''}
					placeholder='••••••••'
					onChange={e => setUser({ ...user, password: e.target.value })}
					className='rounded-l-0'
				/>
			</FormGroup>

			<FormGroup className='pb-4'>
				<TextLink size='' href='/forgot-password' color='blue-500 dark:cyan-500'>
					Forgot your password?
				</TextLink>
			</FormGroup>
		</Fragment>
	);
};

export { LoginFields };
