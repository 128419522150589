import { Card, CardBody } from '@playbooks/interface/cards';
import { H5, H6 } from '@playbooks/interface/fonts';
import { Span } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

export const UpvoteSkeleton = ({ tailwind }) => {
	// Render
	return (
		<Card border='border-b' borderRadius='' display='flex-between' space='space-x-4' spacing='py-4'>
			<CardBody display='flex-start' spacing='space-x-8' width='w-2/3'>
				<SkeletonWrapper className='h-16 rounded-full aspect-[1/1]' />
				<SkeletonWrapper className='h-24 rounded-md aspect-[1/1]' />
				<Span space='space-y-2' width='w-full'>
					<H5>
						<SkeletonWrapper width='50%' />
					</H5>
					<H6>
						<SkeletonWrapper width='75%' />
					</H6>
				</Span>
			</CardBody>
			<CardBody flex='shrink-0'>
				<Span className='h-20 w-20'>
					<SkeletonWrapper className='aspect-[1/1]' />
				</Span>
			</CardBody>
		</Card>
	);
};
