import { Card, CardBody, CardSubtitle, CardTitle } from '@playbooks/interface/cards';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const PhotoSkeleton = ({ tailwind }) => {
	// Render
	return (
		<Card bgColor='' border='border' borderRadius='rounded-md' display='flex-column' spacing='p-4' {...tailwind?.card}>
			<SkeletonWrapper width='100%' className='aspect-[1/1] rounded-md' />
			<CardBody spacing='pt-4' {...tailwind?.body}>
				<CardTitle display='block' {...tailwind?.title}>
					<SkeletonWrapper width='50%' />
				</CardTitle>
				<CardSubtitle display='block' {...tailwind?.subtitle}>
					<SkeletonWrapper width='75%' />
				</CardSubtitle>
			</CardBody>
		</Card>
	);
};

export { PhotoSkeleton };
