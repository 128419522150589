import { Card, CardBody, CardFooter } from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const DisplaySkeleton = ({ tailwind }) => {
	// Render
	return (
		<Card bgColor='' display='flex-column' spacing='' {...tailwind?.card}>
			<CardBody display='flex-between' space='space-x-4'>
				<Span width='w-16'>
					<SkeletonWrapper className='aspect-[1/1]' />
				</Span>
			</CardBody>
			<CardBody flex='grow' space='space-y-4'>
				<h5>
					<SkeletonWrapper width='50%' />
				</h5>
				<h6>
					<SkeletonWrapper width='75%' />
				</h6>
				<h6>
					<SkeletonWrapper width='75%' />
				</h6>
			</CardBody>
			<CardFooter display='flex-between' space='space-x-4'>
				<Span width='w-24'>
					<SkeletonWrapper />
				</Span>
				<Span width='w-24'>
					<SkeletonWrapper />
				</Span>
			</CardFooter>
		</Card>
	);
};

export { DisplaySkeleton };
