import { Card, CardBody } from '@playbooks/interface/cards';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const AvatarSkeleton = ({ tailwind }) => (
	<Card border='border-b' display='flex-start' shadow='' space='space-x-4' spacing='p-4'>
		<SkeletonWrapper className='w-12 h-12 rounded-full' />
		<CardBody space='space-y-2' width='w-full'>
			<h5>
				<SkeletonWrapper width='50%' />
			</h5>
			<h6>
				<SkeletonWrapper width='75%' />
			</h6>
		</CardBody>
	</Card>
);

export { AvatarSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
