import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';
import { Item } from 'molecules/items';

const ItemSkeleton = ({ tailwind }) => (
	<Item type='text' keyName={<SkeletonWrapper width='20%' />} value={<SkeletonWrapper width='20%' />} width='w-full' />
);

export { ItemSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
