import { useState } from 'react';

import { Btn } from '@playbooks/interface/buttons';
import { Form } from '@playbooks/interface/forms';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@playbooks/interface/modals';
import { LegalDisclaimer } from 'components/auth/legal-disclaimer';
import { LoginFields } from 'components/auth/login-fields';
import { GithubOAuthBtn } from 'components/oauth/github-oauth-btn';
import { useStore } from 'contexts';
import { useAction } from 'hooks';

const AuthFlowLogin = ({ open, setOpen, onClose, onView, onNext }) => {
	const [user, setUser] = useState<any>({});
	const store = useStore();

	// Actions
	const [onSave, saveTask] = useAction({}, async () => {
		const response = await store.createRecord({ url: '/auth/login', data: user });
		await onNext(response.data);
	});

	// Methods
	const onSubmit = e => {
		e.preventDefault();
		onSave();
	};

	// Render
	return (
		<Form onSubmit={onSubmit}>
			<ModalHeader onClose={onClose}>
				<ModalTitle>Login</ModalTitle>
			</ModalHeader>
			<ModalBody size='sm'>
				<LoginFields user={user} setUser={setUser} />
			</ModalBody>
			<ModalFooter display='block' space='space-y-4'>
				<Btn type='submit' size='lg' width='w-full' taskRunning={saveTask.running}>
					Login
				</Btn>
				<GithubOAuthBtn onNext={onNext} />
				<LegalDisclaimer />
			</ModalFooter>
		</Form>
	);
};

export { AuthFlowLogin };
