import { Card, CardBody } from '@playbooks/interface/cards';
import { H5, H6 } from '@playbooks/interface/fonts';
import { Span } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

export const SubmitSkeleton = ({ tailwind }) => {
	// Render
	return (
		<Card display='flex-between' space='space-x-4' spacing='p-4'>
			<CardBody display='flex-start' space='space-x-4' width='w-3/4' spacing=''>
				<SkeletonWrapper className='h-20 rounded-md aspect-[1/1]' />
				<Span space='space-y-2' width='w-full'>
					<H5>
						<SkeletonWrapper width='50%' />
					</H5>
					<H6>
						<SkeletonWrapper width='75%' />
					</H6>
				</Span>
			</CardBody>
			<CardBody spacing=''>
				<Span className='w-8'>
					<H6>
						<SkeletonWrapper width='100%' />
					</H6>
				</Span>
			</CardBody>
		</Card>
	);
};
