import { H6 } from '@playbooks/interface/fonts';
import { Col, Grid } from '@playbooks/interface/grid';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';
import { listBuilder } from 'utils';

const TableSkeleton = ({ count, grid, tailwind }) => {
	// Render
	return (
		<Grid {...grid} spacing='py-4'>
			{listBuilder(count).map((s, i) => (
				<Col key={i}>
					<Grid className='flex items-center px-4 py-2 w-full'>
						<Col span='1'>
							<SkeletonWrapper circle={true} height={32} width={32} />
						</Col>
						<Col span='2'>
							<H6>
								<SkeletonWrapper />
							</H6>
						</Col>
						<Col span='2'>
							<H6>
								<SkeletonWrapper />
							</H6>
						</Col>
						<Col span='2'>
							<H6>
								<SkeletonWrapper />
							</H6>
						</Col>
						<Col span='2'>
							<H6>
								<SkeletonWrapper />
							</H6>
						</Col>
						<Col span='2'>
							<H6>
								<SkeletonWrapper />
							</H6>
						</Col>
						<Col span='1'>
							<H6>
								<SkeletonWrapper />
							</H6>
						</Col>
					</Grid>
				</Col>
			))}
		</Grid>
	);
};

export { TableSkeleton };
