import { Avatar, AvatarBody, AvatarImg, AvatarText } from '@playbooks/interface/avatars';
import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';

export const ModalAvatar = ({ thumbnail, title, subtitle, badge }) => {
	// Render
	return (
		<Avatar display='flex-between'>
			<Span display='flex-start' space='space-x-4'>
				<AvatarImg size='xs' src={thumbnail} alt={`${title} photo`} />
				<AvatarBody hover='group-hover:underline'>
					<AvatarText>{title}</AvatarText>
				</AvatarBody>
			</Span>
			<Badge>{badge}</Badge>
		</Avatar>
	);
};
