import { Fragment } from 'react';

import { Li, Span } from '@playbooks/interface/html';
import { AccentLink } from '@playbooks/interface/links';
import { SectionSubtitle } from '@playbooks/interface/sections';

export const LinkItem = ({ keyName, loading, value, display, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-between' space='space-x-8' spacing='py-4' {...tailwind?.li}>
			<SectionSubtitle fontSize='text-sm'>{keyName}</SectionSubtitle>
			{value ? (
				<Fragment>
					<AccentLink
						size=''
						bgColor=''
						nextIcon='arrow-up-right'
						color='blue-500 dark:cyan-500'
						hover='h:underline'
						href={value}
						target='_blank'>
						{display ? display : 'View'}
					</AccentLink>
				</Fragment>
			) : (
				<Span>--</Span>
			)}
		</Li>
	);
};
