import { useEffect, useState } from 'react';

import { Modal } from '@playbooks/interface/modals';
import { AuthFlowLogin } from 'components/auth/auth-flow-login';
import { AuthFlowRegister } from 'components/auth/auth-flow-register';
import { useStore, useToast } from 'contexts';

const AuthFlow = ({ open, setOpen, onNext }) => {
	const [view, setView] = useState('');
	const store = useStore();
	const toast = useToast();

	// Hooks
	useEffect(() => {
		open ? setView('login') : setView('');
	}, [open]);

	// Methods
	const onClose = () => setOpen(!open);

	const onView = v => setView(v);

	// Render
	const props = { open, setOpen, onClose, onView };
	return (
		<Modal open={open} onClose={onClose}>
			{view === 'login' && <AuthFlowLogin {...props} onNext={onNext} />}
			{view === 'register' && <AuthFlowRegister {...props} onNext={onNext} />}
		</Modal>
	);
};

export { AuthFlow };
