import { Small } from '@playbooks/interface/fonts';
import { FormGroup } from '@playbooks/interface/forms';
import { TextLink } from '@playbooks/interface/links';

const LegalDisclaimer = () => {
	// Render
	return (
		<FormGroup align='text-center' spacing='py-4'>
			<Small>
				I agree to the{' '}
				<TextLink size='' textDecoration='underline' href='/terms'>
					Terms of Service
				</TextLink>{' '}
				and{' '}
				<TextLink size='' textDecoration='underline' href='privacy'>
					Privacy Policy
				</TextLink>
				.
			</Small>
		</FormGroup>
	);
};

export { LegalDisclaimer };
