import { Card, CardBody, CardHeader, CardSubtitle, CardTitle } from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const ListSkeleton = ({ tailwind }) => (
	<Card display='flex-between' space='space-x-4' spacing='p-4' {...tailwind?.card}>
		<Span display='flex-start' space='space-x-4' width='w-full'>
			<CardHeader aspect='aspect-[1/1]' borderRadius='rounded-md' bgColor='' width='w-20 lg:w-24' {...tailwind?.header}>
				<SkeletonWrapper />
			</CardHeader>
			<CardBody spacing='' width='w-full'>
				<CardTitle display='' {...tailwind?.title}>
					<SkeletonWrapper width='50%' />
				</CardTitle>
				<CardSubtitle {...tailwind?.subtitle}>
					<SkeletonWrapper width='75%' />
				</CardSubtitle>
			</CardBody>
		</Span>
		<Span aspect='aspect-[1/1]' flex='shrink-0' width='w-8'>
			<SkeletonWrapper />
		</Span>
	</Card>
);

export { ListSkeleton };
