import { Li, Span, Ul } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const ContentSkeleton = ({ tailwind }) => (
	<Ul spacing='' width='w-full'>
		<Li border='border-b' display='flex-start' space='space-x-4' spacing='p-4' width='w-full'>
			<Span flex='shrink-0' width='w-10'>
				<SkeletonWrapper />
			</Span>
			<Span width='w-full'>
				<SkeletonWrapper />
			</Span>
		</Li>
		<Li border='border-b' display='flex-start' space='space-x-4' spacing='p-4' width='w-full'>
			<Span flex='shrink-0' width='w-10'>
				<SkeletonWrapper />
			</Span>
			<Span width='w-full'>
				<SkeletonWrapper />
			</Span>
		</Li>
		<Li border='border-b' display='flex-start' space='space-x-4' spacing='p-4' width='w-full'>
			<Span flex='shrink-0' width='w-10'>
				<SkeletonWrapper />
			</Span>
			<Span width='w-full'>
				<SkeletonWrapper />
			</Span>
		</Li>
	</Ul>
);

export { ContentSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
