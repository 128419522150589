import {
	CardAvatar,
	CommentAvatar,
	HeroAvatar,
	MetaAvatar,
	ModalAvatar,
	TableAvatar,
	ThumbnailAvatar,
} from 'molecules/avatars';

export const Avatar = props => {
	switch (props.type) {
		case 'card':
			return <CardAvatar {...props} />;
		case 'comment':
			return <CommentAvatar {...props} />;
		case 'hero':
			return <HeroAvatar {...props} />;
		case 'meta':
			return <MetaAvatar {...props} />;
		case 'modal':
			return <ModalAvatar {...props} />;
		case 'table':
			return <TableAvatar {...props} />;
		case 'thumbnail':
			return <ThumbnailAvatar {...props} />;
		default:
			return null;
	}
};
