import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { tailwindClassBuilder } from '@playbooks/interface/utils';
import { useTheme } from 'contexts';

const SkeletonWrapper = ({
	theme = '',
	circle = false,
	height = '100%',
	width = '100%',
	className = '',
	...tailwind
}) => {
	const { isDark } = useTheme();
	const color = theme ? theme.color : isDark ? '#374151' : '#e5e7eb';
	const highlight = theme ? theme.highlight : isDark ? '#4b5563' : '#d1d5db';
	const base = { width: 'w-full' };
	const classes = tailwindClassBuilder({ ...base, ...tailwind, className });

	return (
		<SkeletonTheme baseColor={color} highlightColor={highlight}>
			<Skeleton circle={circle} height={height} width={width} className={className} />
		</SkeletonTheme>
	);
};

export { SkeletonWrapper };

// Docs
// https://github.com/dvtng/react-loading-skeleton#readme
