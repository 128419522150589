import { Fragment, useState } from 'react';
import { useRouter } from 'next/router';

import { AccentBtn, BorderBtn, Btn } from '@playbooks/interface/buttons';
import { DropBtn } from '@playbooks/interface/drops';
import { AuthFlow } from 'components/auth/auth-flow';
import { useSession } from 'contexts';

const AuthBtn = ({ type = '', onClick, ...props }) => {
	const [open, setOpen] = useState(false);
	const router = useRouter();
	const session = useSession();

	// Methods
	const onAuth = (...props) => {
		return !session.isAuthenticated() ? router.push(`/login?redirect=${router.asPath}`) : onClick(...props);
	};

	const onRefresh = () => {};

	// Render
	return (
		<Fragment>
			{type === '' && <Btn onClick={onAuth} {...props} />}
			{type === 'accent' && <AccentBtn onClick={onAuth} {...props} />}
			{type === 'border' && <BorderBtn onClick={onAuth} {...props} />}
			{type === 'drop' && <DropBtn onClick={onAuth} {...props} />}
			<AuthFlow open={open} setOpen={setOpen} onNext={onRefresh} />
		</Fragment>
	);
};

export { AuthBtn };
