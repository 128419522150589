import { Card, CardBody, CardHeader } from '@playbooks/interface/cards';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const DetailSkeleton = ({ tailwind }) => (
	<Card overflow='overflow-hidden' spacing='mb-4'>
		<CardHeader borderRadius='' aspect='aspect-[4/2]' overflow='overflow-hidden'>
			<SkeletonWrapper height='100%' width='100%' />
		</CardHeader>
		<CardBody space='space-y-2'>
			<h6>
				<SkeletonWrapper width='75%' />
			</h6>
			<h6>
				<SkeletonWrapper width='50%' />
			</h6>
			<h6>
				<SkeletonWrapper width='25%' />
			</h6>
		</CardBody>
	</Card>
);

export { DetailSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
