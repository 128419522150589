import { Card, CardBody, CardHeader, CardSubtitle, CardTitle } from '@playbooks/interface/cards';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const PillSkeleton = ({ tailwind }) => (
	<Card display='flex-start' space='space-x-4' spacing='px-4 py-2' {...tailwind?.card}>
		<CardHeader aspect='aspect-[1/1]' borderRadius='rounded-md' bgColor='' width='w-16' {...tailwind?.header}>
			<SkeletonWrapper />
		</CardHeader>
		<CardBody spacing='space-y-2' width='w-3/4' {...tailwind?.body}>
			<CardTitle display='' {...tailwind?.title}>
				<SkeletonWrapper width='50%' />
			</CardTitle>
			<CardSubtitle {...tailwind?.subtitle}>
				<SkeletonWrapper width='75%' />
			</CardSubtitle>
		</CardBody>
	</Card>
);

export { PillSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
