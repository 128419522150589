import { Fragment } from 'react';

import { Avatar, AvatarBody, AvatarImg } from '@playbooks/interface/avatars';
import { AccentBtn } from '@playbooks/interface/buttons';
import { Li, Ul } from '@playbooks/interface/html';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { AccentLink } from '@playbooks/interface/links';
import { SectionBody, SectionHeader, SectionSubtitle } from '@playbooks/interface/sections';

const AccountSwitchFragment = ({ session, account, onSelect, tailwind }) => {
	const teams = session.user?.teams || [];

	// Methods
	// Methods
	const isActive = (type, newAccount) => {
		return account.type === type && account.account.id === newAccount.id
			? { icon: 'check', color: 'green-400' }
			: { icon: 'chevron-right' };
	};

	// Render
	return (
		<Fragment>
			<SectionBody border='border-b' spacing=''>
				<SectionHeader border='border-b' spacing='py-3 p-4'>
					<SectionSubtitle fontSize='text-sm' fontWeight='font-medium'>
						Personal
					</SectionSubtitle>
				</SectionHeader>

				<Ul spacing='p-4' {...tailwind?.list}>
					<Li>
						<AccentBtn
							size='md'
							display='flex-start'
							nextIcon={isActive('User', session.user)}
							onClick={() => onSelect('User', session.user)}
							span={{ width: 'w-full' }}
							width='w-full'>
							<Avatar space='space-x-4' width='w-full'>
								<AvatarImg size='w-6 h-6' src={session.user?.thumbnail} />
								<AvatarBody>{session.user?.name}</AvatarBody>
							</Avatar>
						</AccentBtn>
					</Li>
				</Ul>
			</SectionBody>

			<SectionBody spacing=''>
				<SectionHeader border='border-b' spacing='py-3 p-4'>
					<SectionSubtitle fontSize='text-sm' fontWeight='font-medium'>
						Teams
					</SectionSubtitle>
				</SectionHeader>

				<Ul spacing='p-4' {...tailwind?.list}>
					{teams.map((team, i) => (
						<Li key={i}>
							<AccentBtn
								size='md'
								display='flex-start'
								nextIcon={isActive('Team', team)}
								onClick={() => onSelect('Team', team)}
								span={{ width: 'w-full' }}
								width='w-full'>
								<Avatar space='space-x-4'>
									<AvatarImg size='w-6 h-6' src={team?.thumbnail} />
									<AvatarBody>{team?.name}</AvatarBody>
								</Avatar>
							</AccentBtn>
						</Li>
					))}
					<Li>
						<AccentLink
							size='md'
							nextIcon='chevron-right'
							href='/settings/teams'
							span={{ width: 'w-full' }}
							width='w-full'>
							<Span display='flex-start' space='space-x-4'>
								<FadIcon icon='circle-plus' color='gray-600 dark:gray-400' fontSize='text-2xl' />
								<Span>New Team</Span>
							</Span>
						</AccentLink>
					</Li>
				</Ul>
			</SectionBody>
		</Fragment>
	);
};

export { AccountSwitchFragment };
