import { Avatar, AvatarBody, AvatarImg, AvatarText } from '@playbooks/interface/avatars';
import { LinkWrapper } from '@playbooks/interface/links';

export const CommentAvatar = ({ thumbnail, title, href }) => (
	<LinkWrapper group='group' href={href}>
		<Avatar width='w-inherit'>
			<AvatarImg size='xs' src={thumbnail} alt={`${title} photo`} />
			<AvatarBody>
				<AvatarText hover='group-hover:underline'>{title}</AvatarText>
			</AvatarBody>
		</Avatar>
	</LinkWrapper>
);
