import { Badge } from '@playbooks/interface/badges';
import { Small } from '@playbooks/interface/fonts';
import { Li } from '@playbooks/interface/html';
import { SectionActions, SectionSubtitle } from '@playbooks/interface/sections';
import { Skeleton } from 'molecules/skeletons';

export const BadgeItems = ({ keyName, loading, value = [], tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-between' space='space-x-8' spacing='py-4' {...tailwind?.li}>
			<SectionSubtitle fontSize='text-sm'>{keyName}</SectionSubtitle>
			{loading ? (
				<Skeleton className='w-20' />
			) : (
				<SectionActions flex='flex-wrap'>
					{value?.length > 0 ? (
						value.map((v, i) => (
							<Badge key={i} type={v} className='mb-1'>
								{v}
							</Badge>
						))
					) : (
						<Small>--</Small>
					)}
				</SectionActions>
			)}
		</Li>
	);
};
