import { useState } from 'react';
import { useRouter } from 'next/router';

import { Drop, DropItem, DropLink, DropList, DropMenu, DropToggle } from '@playbooks/interface/drops';
import { FadIcon } from '@playbooks/interface/icons';
import { useSession } from 'contexts';

const GuestDrop = ({ type, rootLink, onNext }) => {
	const [open, setOpen] = useState(false);
	const router = useRouter();
	const session = useSession();

	// Methods
	const onToggle = () => setOpen(!open);

	const onClose = () => setOpen(false);

	// Render
	return (
		<Drop open={open} setOpen={onClose}>
			<DropToggle type={type} size='xs' nextIcon='' active={open} onClick={onToggle}>
				<FadIcon icon='circle-user' fontSize='text-2xl' />
			</DropToggle>

			<DropMenu open={open} spacing='right-0 mt-4' width='w-96'>
				<DropList>
					<DropItem>
						<DropLink href='/login'>Login</DropLink>
					</DropItem>

					<DropItem>
						<DropLink href='/register'>Register</DropLink>
					</DropItem>

					<DropItem border='border-b' spacing='px-2' />

					<DropItem>
						<DropLink href='/about'>About</DropLink>
					</DropItem>

					<DropItem>
						<DropLink href='/pricing'>Pricing</DropLink>
					</DropItem>

					<DropItem>
						<DropLink href='/blog'>Blog</DropLink>
					</DropItem>

					<DropItem>
						<DropLink href='/faq'>FAQs</DropLink>
					</DropItem>

					<DropItem>
						<DropLink href='/support'>Support</DropLink>
					</DropItem>

					<DropItem border='border-b' spacing='px-2' />

					<DropItem>
						<DropLink href='/terms'>Terms</DropLink>
					</DropItem>
					<DropItem>
						<DropLink href='/privacy'>Privacy</DropLink>
					</DropItem>
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { GuestDrop };
