import { Badge } from '@playbooks/interface/badges';
import { BtnWrapper } from '@playbooks/interface/buttons';
import {
	Card,
	CardActions,
	CardBody,
	CardHeader,
	CardIcon,
	CardImg,
	CardPretitle,
	CardSubtitle,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { Skeleton } from 'molecules/skeletons';
import { HtmlType } from 'types';

type iInfoCard = HtmlType & {
	icon?: string;
	photo?: string;
	status?: string;
	title?: string;
	subtitle?: string;
	text?: string;
	loading?: string;
};

export const InfoCard = ({
	icon,
	photo,
	status,
	pretitle,
	title,
	subtitle,
	text,
	meta,
	loading,
	tailwind,
	children,
}: iInfoCard) => {
	// Render
	if (loading) return <Skeleton type='action' tailwind={tailwind} />;
	return (
		<Card
			border='border-b'
			borderRadius=''
			display='flex-between'
			hover='group-hover:bg-gray-100 group-hover:dark:bg-gray-800'
			space='space-x-8'
			spacing='py-4'
			{...tailwind?.card}>
			<Span display='flex-start' space='space-x-4' width='w-full'>
				<CardHeader
					aspect='aspect-[1/1]'
					borderRadius='rounded-md'
					display='flex-middle'
					overflow='overflow-hidden'
					spacing=''
					width='w-24'
					{...tailwind?.header}>
					{photo ? (
						<CardImg src={photo} alt={title + ' photo'} width='w-full' />
					) : (
						<CardIcon icon={icon} {...icon} {...tailwind?.icon} />
					)}
				</CardHeader>
				<CardBody display='flex-column' spacing='' width='w-full' {...tailwind?.body}>
					{pretitle && <CardPretitle>{pretitle}</CardPretitle>}
					<CardTitle>
						<Span>{title}</Span>
					</CardTitle>
					{subtitle && <CardSubtitle className='line-clamp-2'>{subtitle}</CardSubtitle>}
					{text && <CardText>{text}</CardText>}
					{meta}
				</CardBody>
			</Span>
			<CardActions display='flex-end' flex='shrink-0' {...tailwind?.actions}>
				{status && (
					<Badge size='xs' type={status}>
						{status}
					</Badge>
				)}
			</CardActions>
		</Card>
	);
};

export const InfoBtnCard = props => (
	<BtnWrapper onClick={props.onClick} cursor='cursor-pointer' group='group' width='w-full'>
		<InfoCard {...props}>
			<FarIcon icon='chevron-right' />
		</InfoCard>
	</BtnWrapper>
);
